export interface AuthLoginType {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  username: string;
}

export class AuthLoginModel {
  accessToken: string;
  tokenType: string;
  refreshToken: string;
  expiresIn: number;
  scope: string;
  username: string;

  convert(dto: AuthLoginType): AuthLoginModel {
    if (dto.access_token) {
      this.accessToken = dto.access_token;
    }
    if (dto.token_type) {
      this.tokenType = dto.token_type;
    }
    if (dto.refresh_token) {
      this.refreshToken = dto.refresh_token;
    }
    if (dto.expires_in) {
      this.expiresIn = dto.expires_in;
    }
    if (dto.scope) {
      this.scope = dto.scope;
    }
    if (dto.username) {
      this.username = dto.username;
    }

    return this;
  }

  fromLocalStorage(dto: any): AuthLoginModel {
    if (dto.accessToken) {
      this.accessToken = dto.accessToken;
    }
    if (dto.tokenType) {
      this.tokenType = dto.tokenType;
    }
    if (dto.refreshToken) {
      this.refreshToken = dto.refreshToken;
    }
    if (dto.expiresIn) {
      this.expiresIn = dto.expiresIn;
    }
    if (dto.scope) {
      this.scope = dto.scope;
    }
    if (dto.username) {
      this.username = dto.username;
    }

    return this;
  }
}
